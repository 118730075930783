<template>

  <Warning :show="showDeleteConfirm" message="Confirm you want to delete this category?" primary="Delete" @save="removeDiscount"/>
  <DiscountModal :show="showDiscountModal" :discount="currentDiscount" @save="saveDiscount" @remove="removeDiscount" />

  <div v-if="discounts">

    <h4>
      Discounts
    </h4>

    <ul :class="(tableMode ? 'table' : 'grid') + ' wrapper'" ref="history">
      <li @click="showDiscount(discount.value)" v-bind:key="idx" v-for="(discount, idx) in discounts" class="modifierBox">
        <div class="box">
          <p style="text-align: center;">
            <span class="modifier-name">{{ discount.value.name }}</span>
            <span class="modifier-price">{{ discount.value.type ? '$' + discount.value.value.toFixed(2) : discount.value.value + '%' }} </span>
          </p>
        </div>
      </li>

      <li @click="showDiscount(null)" class="modifierBox newModifier">
        <div class="box">
          <p style="text-align: center;">
            <span class="modifier-icon"><font-awesome-icon icon="plus" /></span>
            <span class="modifier-name" >Add Discount</span>
          </p>
        </div>
      </li>

    </ul>
  </div>

</template>

<script>
import Warning from "@/components/_layouts/Warning";

import {mapGetters} from "vuex";
// import createdMixin from "@/components/_mixins/createdMixin";
import DiscountModal from "@/components/Discounts/DiscountModal";

import Slug from "slug";

export default  {
  name: 'Discounts',
  // mixins: [createdMixin],
  components: {
    Warning,
    DiscountModal
  },
  props: ['channel'],

  data() {
    return {
      currentDiscount: null,
      showDeleteConfirm: false,
      loaded: false,
      showBarMenu: false,
      showDiscountModal: false,
      tableMode: false,
      newChannel: null
    }
  },

  methods: {
    confirmDelete() {
      this.showDeleteConfirm = ! this.showDeleteConfirm;
    },
    showDiscount(discount_doc) {
      if ( discount_doc ) {
        this.currentDiscount = discount_doc;
      }
      else {
        this.currentDiscount = { pending: true, name: null, type: null, value: null, description: null, active: false };
      }
      this.showDiscountModal = ! this.showDiscountModal;
    },
    async saveDiscount(discount) {
      var foundDiscount = this.discounts.find(p => {
        return p.id === 'discount:' + Slug(discount.name)
      });

      if (foundDiscount) {
        await this.newChannel.push("document:update", discount);
      } else {
        discount._id = 'discount:' + Slug(discount.name);
        await this.newChannel.push("document:new", discount);
      }
    },
    async removeDiscount(doc) {
      await this.newChannel.push("document:delete", { doc: doc._id, rev: doc._rev });
    }
  },
  mounted(){
    this.newChannel = this.channel;
  },

  watch: {
  },

  computed: {
    ...mapGetters({
      discounts: 'discounts'
    })
  }
}
</script>
<style src="@vueform/toggle/themes/default.css"></style>

<style scoped lang="scss">
@import "public/layout";
@import "public/wrapper";
@import "public/actions";

</style>
