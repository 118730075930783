<template>
  <teleport to="body">
    <div
      v-if="openModal"
      class="modal"
      @click.self.prevent="
        openModal = !openModal;
        openNotesModal = false;
      ">
      <div>
        <font-awesome-icon icon="fa-circle-xmark" class="exit" @click="openModal = !openModal" />
        <button @click.stop.prevent="openModal = !openModal; $emit('saveHours')" class="save-btn">Save Changes</button>
        <div>
          <ul>
            <h2>Opening Hours</h2>
            <DayOperatingHours
              :open="daySetOpen"
              :openTime="daySetOpenTime"
              :closeTime="daySetCloseTime"
              @setOpenTime="daySetOpenTime = $event"
              @setCloseTime="daySetCloseTime = $event"
              @setOpen="daySetOpen = $event" />
            <button @click.stop.prevent="updateDays" class="set-btn">Set All Days</button>
            <li v-for="day in week" :key="day.name">
              <span class="days"
                ><span>{{ day.name }}</span>
                <DayOperatingHours
                  :open="day.open"
                  :openTime="day.openTime"
                  :closeTime="day.closeTime"
                  @setOpenTime="day.openTime = $event"
                  @setCloseTime="day.closeTime = $event"
                  @setOpen="day.open = $event" />
              </span>
            </li>
          </ul>
          <ul>
            <h2>Public Holidays</h2>
            <div class="year-change">
              <button v-if="currentYear == nextYear" @click.stop.prevent="$emit('updateYear', thisYear)"><font-awesome-icon icon="fa-arrow-left" /></button>
              <h2>{{ currentYear }}</h2>
              <button v-if="currentYear == thisYear" @click.stop.prevent="$emit('updateYear', nextYear)"><font-awesome-icon icon="fa-arrow-right" /></button>
            </div>
            <DayOperatingHours
              :open="holidaySetOpen"
              :openTime="holidaySetOpenTime"
              :closeTime="holidaySetCloseTime"
              @setOpenTime="holidaySetOpenTime = $event"
              @setCloseTime="holidaySetCloseTime = $event"
              @setOpen="holidaySetOpen = $event" />
            <button @click.stop.prevent="updateHolidayDays" class="set-btn">Set All Holidays</button>
            <li v-for="holiday in holidays" :key="holiday">
              <span v-if="holiday.name != ''" class="days"
                ><span>{{ holiday.name }} {{ holiday.date }}</span>
                <DayOperatingHours
                  :open="holiday.open"
                  :openTime="holiday.openTime"
                  :closeTime="holiday.closeTime"
                  @setOpenTime="holiday.openTime = $event"
                  @setCloseTime="holiday.closeTime = $event"
                  @setOpen="holiday.open = $event" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";
import DayOperatingHours from "./DayOperatingHours.vue";
export default {
  components: {
    DayOperatingHours,
  },
  name: "OperatingHoursModal",
  props: ["open", "close", "thisYear", "nextYear", "holidays", "currentYear", "week"],
  data() {
    return {
      openModal: false,
      openNotesModal: false,
      orderStatus: "In Progress",
      daySetOpen: true,
      daySetOpenTime: "08:30",
      daySetCloseTime: "14:30",
      holidayDays: {},
      weekDays: {},
      holidaySetOpen: true,
      holidaySetOpenTime: "08:30",
      holidaySetCloseTime: "14:30",
    };
  },

  methods: {
    updateDays() {
      this.week.forEach((day) => {
        day.open = this.daySetOpen;
        day.openTime = this.daySetOpenTime;
        day.closeTime = this.daySetCloseTime;
      });
    },
    updateHolidayDays() {
      this.holidays.forEach((day) => {
        day.open = this.holidaySetOpen;
        day.openTime = this.holidaySetOpenTime;
        day.closeTime = this.holidaySetCloseTime;
      });
    },
  },

  mounted() {
  },

  emits: ["showOperatingHoursModal", "updateYear", "saveHours"],

  computed: {
    ...mapGetters(["getWebOrder", "futurePickup"]),

    orderComplete() {
      return this.getWebOrder.order.products.every((product) => product.complete);
    },

    formattedDate() {
      const date = new Date(this.getWebOrder.order.pickup_date);
      return date.toDateString();
    },
  },
  watch: {
    open() {
      this.openModal = true;
    },
    close() {
      this.openModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";
@import "public/search";
.modal {
  div {
    min-width: 80vw;
    max-width: 85vw;
    .save-btn {
      margin: 0.5rem;
      background-color: var(--banner-colour);
      color: var(--alt-text-colour);
      border: 1px solid var(--action-colour);
      transition: all 500ms ease-in-out;
      font-size: larger;
      &:hover {
        background-color: var(--action-colour);
        color: var(--banner-colour);
      }
    }
    div {
      max-width: 40vw;
      min-width: 30vw;
      border-radius: 10px;
      display: flex;
      flex-direction: row;

      ul {
        padding: 0;
        margin: 0;
        div {
          button {
            padding: 0 1rem;
            margin: 1rem;
          }
        }
        .set-btn {
          margin-left: 0;
        }
        li {
          list-style: none;
          margin: 0.5rem;
          .days {
            span {
              width: 150px;
            }
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
  }
  .product {
    h3 {
      margin: 0.5rem;
    }
    .modifiers {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: flex-start;
      overflow: scroll;

      .modifiers-layout {
        flex: 0 0 25%;
        background-color: var(--primary-colour);
        padding: 1rem;
        border-radius: 0.25rem;
        margin: 1rem;
        @media only screen and (orientation: portrait) {
          flex: 0 0 75%;
        }
        p,
        h3 {
          margin: 0;
        }
        svg {
          font-size: 2rem;
          color: var(--action-colour);
          float: right;
        }
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .modal {
    div {
      div {
        display: flex;
        flex-direction: column;
        ul {
          .year-change {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 30%;
            h2 {
              margin: 5px;
              min-width: 4rem;
            }
          }
          li {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            span {
              span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
  }
}
</style>
