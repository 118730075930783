<template>
  <div class="day-container">
    <Toggle class="toggle" onLabel="Open" offLabel="Closed" v-model="openClose" />
    <div v-if="openClose" class="time-input">
      <span><input type="time" v-model="localOpenTime" /> <input type="time" v-model="localCloseTime" /></span>
    </div>
  </div>
</template>

<script>
import Toggle from "@vueform/toggle";

export default {
  name: "DayOperatingHours",
  props: ["open", "openTime", "closeTime"],
  emit: ["setOpenTime"],
  components: {
    Toggle,
  },
  data() {
    return {
      openClose: this.open,
      localOpenTime: this.openTime,
      localCloseTime: this.closeTime,
      // Your component's data goes here
    };
  },
  methods: {
    // Your component's methods go here
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
  watch: {
    localOpenTime() {
      this.$emit("setOpenTime", this.localOpenTime);
    },
    localCloseTime() {
      this.$emit("setCloseTime", this.localCloseTime);
    },
    openClose() {
      this.$emit("setOpen", this.openClose);
    },
    open() {
      this.openClose = this.open;
    },
    openTime() {
      this.localOpenTime = this.openTime;
    },
    closeTime() {
      this.localCloseTime = this.closeTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.day-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem 0;
  border-radius: 10px;
  div {
    // margin: 0 5px;
    span {
      input {
        width: 100px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid var(--action-colour);
        padding: 0 5px;
        margin: 0 5px;
        background-color: var(--banner-colour);
        color: var(--alt-text-colour);
      }
    }
  }

  .toggle {
    --toggle-width: 8rem;
    --toggle-border-off: black;
    --toggle-border-on: black;
    --toggle-bg-on: var(--action-colour);
    --toggle-border: 2px;
    --toggle-height: 30px;
    --toggle-font-size: 1rem;
    outline-color: green;
    outline: 0;
    border-color: var(--action-colour);
    border-left-width: 1px;
    border-right-width: 1px;
    .toggle-handle {
      height: 30px;
      width: 30px;
    }
  }
}
@media only screen and (orientation: portrait) {
  .day-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    div {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        input {
          margin: 3px 0;
        }
      }
    }
  }
}
</style>
