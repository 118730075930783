<template>
  <teleport to="body">
    <div v-if="open" class="modal" @click.self.prevent="open = ! open">
      <div class="wrapper">

        <div class="content">
          <h3 class="title">Create / Edit Discount</h3>

          <label>Active / Available Discount</label>
          <Toggle class="toggle" onLabel="Available" offLabel="Unavailable" v-model="currentDiscount.active" />

          <label>Name</label>
          <input type="text" :disabled="! currentDiscount.pending" v-model="currentDiscount.name" placeholder="e.g. Soy Milk"/>

          <label>Type</label>

          <Toggle class="toggle type" onLabel="Fixed Value" offLabel="Percentage" v-model="currentDiscount.type" />

          <label>Value</label>
          <input type="text" v-model.number="currentDiscount.value" placeholder="123"/>

        </div>

        <div class="actions">
          <button class="action danger" @click.prevent.stop="remove">Delete</button>
          <button class="action cancel" @click.prevent.stop="cancel">Cancel</button>
          <button class="action primary" @click.prevent.stop="commit">Save</button>
        </div>

      </div>

    </div>
  </teleport>
</template>

<script>

import Toggle from '@vueform/toggle'

export default {
  name: 'DiscountModal',
  props: ['show', 'discount'],
  emits: ['save', 'remove'],
  components: { Toggle },
  data() {
    return {
      open: false,
      currentDiscount: { name: null, type: null, value: null, description: null, active: false},
    }
  },
  methods: {
    remove() {
      this.$emit('remove', this.currentDiscount);
      this.open = false;
    },
    cancel() {
      this.open = ! this.open;
    },
    commit() {
      
      this.$emit('save', this.currentDiscount);
      this.open = false;
    },
  },
  watch: {
    show() {
      this.open = true;
    },
    discount() {
      this.currentDiscount = this.discount;
    }
  }
}

</script>
<style src="@vueform/toggle/themes/default.css"></style>

<style lang="scss" scoped>
@import "public/wrapper";

.modal {

  .wrapper {
    background-color: var(--primary-colour);
    color: var(--text-colour);
    padding: 1rem;
    box-shadow: 2px 2px 2px #ccc;
  }

  .cancel{
    color: var(--text-colour);
  }
  div.content {



    textarea { min-height: 100px; height: 4rem !important; }

    img {
      display: block;
      width: 200px;
    }

    ul.modifiersets {
      flex: 1 1;
      list-style: none;
      margin: 0px;
      margin-right: 1rem;
      padding: 0px;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-auto-flow: row;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      //flex-wrap: wrap;
      //justify-content: stretch;

      li button {
        position: relative;
        cursor: pointer;
        flex: 1 1;
        padding: 2rem;
        width: 100%;
        min-height: 100px;
        margin: 0.2rem;
        background-color: #aaa;
        border-color: var(--hover-colour);
        border-style: none;

        &.selected {
          background-color: #dfe7f0;
          border: 1px solid #dfe7f0;
        }
      }
    }

  }
}
 .type{
    --toggle-bg-off: #65da3b;
  }

</style>
